<template>
  <div class="w-full overscroll-y-contain">
    <div
      class="relative w-full text-justify lg:p-[6px]"
      :class="{
        'rounded-br-lg lg:!rounded-bl-lg lg:!rounded-br-none': !message.admin_name,
        'mr-2 rounded-bl-lg': message.admin_name,
      }"
    >
      <div
        class="absolute bottom-0 top-0 w-6 rounded-t-3xl"
        :class="{
          'right-full rounded-bl-3xl lg:!right-auto lg:left-full lg:!rounded-bl-none lg:rounded-br-3xl':
            !message.admin_name,
          'left-full rounded-br-3xl': message.admin_name,
        }"
      >
        <div class="h-full min-h-[40px] w-full rounded-full"></div>
      </div>
      <div v-if="message.admin_name">
        <p class="text-xs font-[700] lg:text-sm">
          {{ 'Оператор ' + message.admin_name }}
        </p>
      </div>
      <p
        class="img_tiptap messageTextBase messageTextArchive prose whitespace-normal !text-pretty break-words text-left text-sm lg:text-base"
        v-html="sanitizedMessage || ' '"
      />
      <div
        v-if="message.files?.length > 0"
        class="flex flex-wrap items-start gap-4 rounded-xl px-[4px] pb-[18px] pt-[4px] lg:p-[14px]"
      >
        <ChatFileView
          v-for="file in message?.files"
          :key="file?.name || file"
          :file="file"
          :loading="message.files_uploading"
        />
      </div>

      <div class="absolute bottom-0 right-0 flex flex-row">
        <div class="flex w-fit items-center justify-between gap-[4px] self-end">
          <div class="flex items-start self-end text-black">
            <UiIcon
              v-if="!message.admin_name"
              :key="message.unread"
              class="block lg:hidden"
              fill-bg="#8C8C8C"
              stroke="#8C8C8C"
              :name="message.unread ? 'mark' : 'markRead'"
              size="xs"
            />
            <UiIcon
              v-if="!message.admin_name"
              :key="message.unread"
              class="hidden lg:block"
              fill-bg="#8C8C8C"
              stroke="#8C8C8C"
              :name="message.unread ? 'mark' : 'markRead'"
              size="sm"
            />
          </div>
          <p class="text-right text-xs text-dnd_dark_grey">
            {{ dayjs(message.created_at || dayjs()).format('HH:mm') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DOMPurify from 'isomorphic-dompurify';
import dayjs from '~/helpers/dayjs';

const props = defineProps({
  message: {
    type: Object,
    default: () => {},
  },
});

const sanitizedMessage = computed(() => {
  return DOMPurify.sanitize(props.message.message);
});
</script>

<style>
.messageTextBase * + br:last-child {
  display: none;
  content: '';
  display: inline-block;
  text-wrap: wrap;
}
.messageTextBase p:last-child::after {
  content: '';
  display: inline-block;
  text-wrap: wrap;
}
.messageTextArchive p:last-child::after {
  width: 50px;
  text-wrap: wrap;
}
</style>
