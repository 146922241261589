export default (array, func) => {
  if (!Array.isArray(array)) return -1;

  let l = array.length - 1;
  while (l >= 0) {
    if (func.apply(null, [array[l]])) {
      break;
    }
    l -= 1;
  }

  return l;
};
