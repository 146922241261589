<template>
  <div
    class="fixed shadow-2xl md:transition-all"
    :class="{
      'bottom-0 left-0 right-0 top-0 z-[2000] md:left-12 md:right-auto md:top-auto md:z-[999]':
        chatStore.isChatOpened,
      'bottom-4 right-4 z-[5] rounded-full md:bottom-0 md:left-12 md:right-auto md:rounded-none':
        !chatStore.isChatOpened,
    }"
  >
    <div
      v-if="chatStore.isChatOpened"
      class="absolute -left-8 box-border hidden cursor-pointer rounded-full border border-black p-2 transition-all hover:opacity-70 md:block"
      @click="chatStore.isChatOpened = false"
    >
      <UiIcon
        class="h-2 w-2 transition-all"
        name="close"
      />
    </div>
    <div
      class="flex flex-col transition-all"
      :class="{
        'h-full w-full md:w-[330px]': chatStore.isChatOpened,
        'md:w-[270px]': !chatStore.isChatOpened,
      }"
    >
      <div
        class="bg-dnd_yellow p-3 md:p-3 md:px-4"
        :class="{
          'md:rounded-t-2xl': chatStore.isChatOpened,
          'cursor-pointer rounded-full md:rounded-b-none md:rounded-t-2xl': !chatStore.isChatOpened,
        }"
        @click="showChat"
      >
        <UiIcon
          size="xl"
          name="chat"
          :class="{
            hidden: chatStore.isChatOpened,
            'flex md:hidden': !chatStore.isChatOpened,
          }"
        />
        <div
          class="items-center justify-center"
          :class="{
            block: chatStore.isChatOpened,
            'hidden md:block': !chatStore.isChatOpened,
          }"
        >
          <span
            v-if="!chatStore.isChatOpened"
            class="absolute -right-0.5 -top-0.5 flex h-3 w-3"
          >
            <span
              class="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75"
            />
            <span class="relative inline-flex h-3 w-3 rounded-full bg-green-500" />
          </span>

          <div
            class="flex items-center"
            :class="{
              'justify-between': chatStore.isChatOpened,
              'justify-center': !chatStore.isChatOpened,
            }"
          >
            <div
              class="flex flex-col"
              :class="{ hidden: !chatStore.isChatOpened }"
            >
              <span class="text-sm">Напишите ваше сообщение</span>
              <span class="text-xs opacity-60">Операторы онлайн!</span>
            </div>
            <div
              class="flex flex-col"
              :class="{ hidden: !chatStore.isChatOpened }"
            />
            <span
              :class="{
                hidden: chatStore.isChatOpened,
                'hidden md:inline': !chatStore.isChatOpened,
              }"
            >
              Напишите нам, мы онлайн!
            </span>
            <div
              class="cursor-pointer md:hidden"
              @click.stop="chatStore.isChatOpened = false"
            >
              <UiIcon
                class="h-5 w-5"
                name="close"
              />
            </div>
          </div>
        </div>
      </div>
      <Transition name="chatMessages">
        <div
          v-show="chatStore.isChatOpened"
          class="relative flex min-h-0 grow flex-col bg-white md:h-[500px] md:max-h-[60vh] md:transition-all"
        >
          <div
            v-if="loadingChat"
            class="absolute inset-0 flex items-center justify-center"
          >
            <UiIcon
              class="h-6 w-6 animate-spin transition-all"
              name="loading"
            />
          </div>
          <div
            v-else
            ref="chatBlock"
            class="flex h-full flex-col overflow-y-auto px-3"
          >
            <div
              v-if="chatStore.messages.length === 0"
              class="flex h-full w-full items-center justify-center"
            >
              <span>Напишите ваше первое сообщение</span>
            </div>
            <div
              v-for="[date, messages] in filteredMessages"
              :key="date"
              class="flex flex-1 flex-col gap-1"
            >
              <div class="relative z-[10] my-3 flex h-fit w-full bg-white lg:sticky lg:top-0">
                <hr class="mr-2.5 flex-grow self-center" />
                <p class="text-xxxs text-dnd_dark_grey lg:text-sm">{{ date }}</p>
                <hr class="ml-2.5 flex-grow self-center" />
              </div>
              <div
                v-for="(_message, msgIdx) in messages"
                :key="_message.id || msgIdx"
                class="relative flex w-fit min-w-[10%] max-w-[80%] flex-col items-start gap-0.5 rounded-2xl bg-dnd_darker_yellow px-3 py-1.5 lg:mr-3 lg:gap-1 xl:max-w-[640px] 3xl:w-[50%]"
                :class="{
                  'self-end bg-dnd_light_grey': !_message?.admin_name,
                }"
              >
                <ChatMessage :message="_message" />
              </div>
            </div>
            <div class="flex items-end gap-2 border-t border-gray-100 p-2">
              <TipTap
                v-model="message"
                placeholder="Введите сообщение"
                class="no-zoom-input h-full max-h-20 min-h-10 w-full overflow-hidden border border-gray-300 text-sm outline-none"
                @update="typingMessage"
                @enter="sendMessage"
              />
              <div
                class="flex h-10 w-10 shrink-0 basis-10 items-center justify-center rounded-lg bg-green-500 transition-all"
                :class="{
                  'cursor-pointer bg-green-500 active:bg-green-600': isAllowedToSendMessage,
                  'bg-zinc-200': !isAllowedToSendMessage,
                }"
                @click="sendMessage"
              >
                <UiIcon
                  class="h-4 w-4 -rotate-90 transform text-white"
                  name="arrowFull"
                />
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import throttle from 'lodash.throttle';
import dayjs from '~/helpers/dayjs';
import findLastIndex from '~/helpers/findLastIndex';

const { $ws } = useNuxtApp();
const chatStore = useChatStore();

const chatFilesStore = useChatFilesStore();

const message = ref('');
const chatBlock = ref(null);
const loadingChat = ref(false);

const isAllowedToSendMessage = computed(() => {
  const strippedMessage = message.value
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/\s+/g, '')
    .trim();

  if (!strippedMessage && !chatFilesStore.files.length) {
    return;
  }

  return message.value.length > 0;
});

const messagesByDates = computed(() => {
  return chatStore.messages.reduce((acc, curr) => {
    const key = dayjs(curr.created_at).format('DD.MM.YYYY');
    if (!acc[key]) acc[key] = [];
    return {
      ...acc,
      [key]: [...acc[key], curr],
    };
  }, {});
});

const filteredMessages = computed(() => {
  return Object.entries(messagesByDates.value).sort(([date1], [date2]) => {
    date1 = dayjs(date1, 'DD.MM.YYYY');
    date2 = dayjs(date2, 'DD.MM.YYYY');
    return date1.diff(date2);
  });
});

const showChat = async () => {
  try {
    chatStore.isChatOpened = true;

    if (!$ws.sockets.chat) {
      await $ws.useConnection();
    }
    await loadMessages();
  } catch (err) {
    console.log(err);
  }
};

const scrollChatToBottom = async (instant = false) => {
  await nextTick();
  if (!filteredMessages.value.length) return;

  if (chatBlock.value) {
    if (instant) {
      chatBlock.value.scrollTop = chatBlock.value.scrollHeight;
    } else {
      chatBlock.value.scrollTo({
        top: chatBlock.value.scrollHeight,
        behavior: 'smooth',
      });
    }
  } else {
    console.error('chatBlock is not defined');
  }
};

const sendMessage = async () => {
  if (!message.value.trim() && !chatFilesStore.files.length) {
    return;
  }

  const newMessage = {
    message: message.value.trim(),
    room_id: chatStore.roomId,
    files: chatFilesStore.files,
    unread: true,
  };

  const randomId = Math.random().toFixed(10).toString();
  message.value = '';

  if (chatFilesStore.files.length) {
    chatFilesStore.files = [];
    chatStore.messages.push({
      ...newMessage,
      client_id: true,
      _id: randomId,
      files_uploading: true,
    });
    const files = await chatFilesStore.uploadFiles(newMessage.files);
    newMessage.files = files;
    const messageIdx = findLastIndex(chatStore.messages, (m) => {
      return m._id === randomId;
    });
    chatStore.messages[messageIdx].files = files;
    chatStore.messages[messageIdx].files_uploading = false;
  } else {
    chatStore.messages.push({
      ...newMessage,
      client_id: true,
      _id: randomId,
    });
    delete newMessage.files;
  }

  $ws.sockets.chat.emit('message:send', newMessage);

  message.value = '';
  chatFilesStore.files = [];
  chatFilesStore.refInput = '';
  await loadMessages();
};

const typingMessage = throttle(() => {
  $ws.sockets.chat.emit('message:typing', chatStore.roomId);
}, 500);

const loadMessages = async () => {
  try {
    await new Promise((resolve) => {
      const intrvl = setInterval(() => {
        if (chatStore.roomId) {
          clearInterval(intrvl);
          resolve();
        }
      }, 1000);
    });

    await chatStore.getChatMessages();
    if (chatStore.hasNonBotUnreadMessages) {
      chatStore.readOperatorMessages();
    }
    scrollChatToBottom();
  } catch (err) {
    console.log('err', err);
  } finally {
    loadingChat.value = false;
  }
};
</script>

<style scoped>
.chatMessages-enter-active,
.chatMessages-leave-active {
  height: 100%;
}

.chatMessages-enter,
.chatMessages-leave-to {
  height: 0;
}

@media screen and (min-width: 768px) {
  .chatMessages-enter-active {
    height: 0px;
  }

  .chatMessages-leave-active {
    height: 500px;
    transition: all 0.6s;
  }
  .chatMessages-enter,
  .chatMessages-leave-to {
    height: 0;
  }
}

.no-zoom-input {
  font-size: 16px;
}
</style>
