<template>
  <a
    :href="fileUrl"
    class="relative block h-[60px] w-[80px] cursor-pointer lg:h-[70px] lg:w-[100px] xl:h-[80px] xl:w-[120px] 2xl:h-[100] 2xl:w-[180px]"
    target="_blank text-black"
  >
    <div class="relative h-full w-full">
      <div
        v-if="!imgType.includes((file.name || file).split('.').pop())"
        class="custom_shadow flex h-full w-full gap-3 rounded-lg bg-white p-2 pr-7"
      >
        <div class="text-black opacity-75">
          <p class="truncate text-sm lg:text-base" />
          <div class="flex flex-col">
            <p class="truncate text-base lg:text-2xl" />
          </div>
        </div>
      </div>

      <div
        v-else
        class="relative h-full w-full rounded-lg bg-cover bg-center bg-no-repeat"
        :style="{ backgroundImage: `url(${fileUrl})` }"
      >
        <div
          v-if="loading"
          class="z-10 h-full w-full"
        >
          <div
            class="absolute inset-0 flex items-center justify-center rounded-md bg-black opacity-50"
          >
            <UiIsLoading white />
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script setup>
const props = defineProps({
  file: { type: String, default: '' },
  edit: { type: Boolean },
  loading: { type: Boolean },
});

const { $getChatFileUrl } = useNuxtApp();

const imgType = ['jpeg', 'jpg', 'png', 'ico', 'gif', 'tiff', 'WebP', 'eps', 'svg'];

const fileUrl = computed(() => {
  try {
    return props.file.name ? URL.createObjectURL(props.file) : $getChatFileUrl(props.file);
  } catch (err) {
    return '';
  }
});
</script>
