<template>
  <div
    class="min-h-[42px] break-all rounded-[32px] bg-dnd_light_grey px-4 py-1 transition-all"
    @click="editor.commands.focus()"
  >
    <TipTapMenuBar
      v-if="visibleMenuBar"
      class="h-full w-full p-2"
      :editor="editor"
    />
    <editor-content
      class="prose h-full w-full cursor-text px-1 py-1.5 !text-black"
      :editor="editor"
    />
  </div>
</template>

<script setup>
import { useEditor, EditorContent } from '@tiptap/vue-3';
import { Extension } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Focus from '@tiptap/extension-focus';
import Placeholder from '@tiptap/extension-placeholder';

const tipTapStore = useTipTapStore();

const props = defineProps({
  visibleMenuBar: {
    type: Boolean,
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  placeholder: {
    type: [String, Number],
    default: 'Сообщение',
  },
});

const emit = defineEmits(['update:modelValue', 'getNoHtml', 'focus', 'blur', 'enter']);

const KeyboardShortcuts = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        emit('enter');
        return true;
      },
    };
  },
});

const editor = useEditor({
  content: props.modelValue,
  extensions: [
    StarterKit,
    Link.configure({
      autolink: true,
    }),
    Placeholder.configure({
      placeholder: props.placeholder,
    }),
    Focus,
    KeyboardShortcuts,
  ],
  onUpdate: ({ editor }) => {
    emit('update:modelValue', editor.getHTML());
    emit('getNoHtml', editor.getText());
  },
  onFocus: () => {
    emit('focus');
  },
  onBlur: () => {
    emit('blur');
  },
});

tipTapStore.editor = editor;

watch(
  () => props.modelValue,
  (newValue) => {
    const isSame = newValue === editor.value.getHTML();
    if (isSame) {
      return;
    }
    setTimeout(() => {
      editor.value.commands.focus();
    }, 0);
    editor.value?.commands.setContent(newValue, false);
  },
);
</script>

<style>
.ProseMirror {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  max-height: 108px;
  overflow-y: auto;
}

.prose {
  color: black;
}

.prose p {
  margin: 0;
}

.prose a {
  text-decoration: none;
  color: #0051ff;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror::-webkit-scrollbar {
  width: 16px;
}

.ProseMirror::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ProseMirror::-webkit-scrollbar-thumb {
  background: lightgray;
  background-clip: padding-box;
  border: 4px solid transparent;
  border-radius: 9999px;
}

.ProseMirror::-webkit-scrollbar-thumb:hover {
  background: #bfbfc2;
  background-clip: padding-box;
}
</style>
